import React, { useState } from 'react'
import './portfolio-post.scss'
import { Link, graphql } from 'gatsby'
// TODO: Optimize payload-weight by removing react-markdown and instead opt to
// parse markdown to html in Build-Environment/Node (`gatsby-node.js`)
import ReactMarkdown from 'react-markdown'
import { getSrcSet, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from 'gbimage-bridge';
import Observer from '@researchgate/react-intersection-observer'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { pathForPortfolioSlug } from '../helper/navigation-helper'

const Markdown = ({ source, ...otherProps }) => {
    return (
        <ReactMarkdown
            // Remove root/wrapping <p>-tag.
            disallowedElements={['p']}
            unwrapDisallowed
            // Links will be treated as external Links.
            components={{
                a: ({ node, ...props }) =>
                    <a
                        href={props.href}
                        rel='nofollow noreferrer noopener'
                        target="_blank"
                    >
                        {props.children}
                    </a>
            }}
            {...otherProps}>
            {source}
        </ReactMarkdown>
    )
}

const PortfolioPostTemplate = ({ data, pageContext, location }) => {
    const post = data.markdownRemark
    const { previous, next } = pageContext
    const [isHeroIntersecting, setHeroIntersecting] = useState(true)

    const handleIntersection = (event) => {
        setHeroIntersecting(event.isIntersecting)
    }

    const iconStyle = { borderRadius: "16px" }

    return (
        <Layout location={location} mainElementClassname='portfolio-post' headerClassname='variant-portfolio-template'>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />
            <Observer onChange={handleIntersection} >
                <div className='hero-placeholder' aria-hidden />
            </Observer>
            <article>
                <header>
                    <BgImage
                        className='hero'
                        image={post.frontmatter.heroImage.childImageSharp.gatsbyImageData}
                        style={{
                            ...(!isHeroIntersecting ? { visibility: 'hidden', } : {}),
                            width: '100%',
                            backgroundSize: 'cover',
                            // NOTE: `backgroundAttachment` doesn't work with iOS Safari (iOS 13.4)
                            // backgroundAttachment: 'fixed',
                            ...(Boolean(post.frontmatter.heroBackgroundPosition) ? {
                                backgroundPosition: post.frontmatter.heroBackgroundPosition,
                            } : {}),
                        }}
                        {...(Boolean(post.frontmatter.heroBackgroundColor) ? {
                            backgroundColor: post.frontmatter.heroBackgroundColor,
                        } : {})}
                    >
                        <div className='hero-overlay' aria-hidden />
                    </BgImage>
                    <div className='container card'> {/* Card */}
                        {post.frontmatter.iconImage ? <GatsbyImage
                            style={iconStyle}
                            // Applying border-radius directly to `img`-element instead of `GatsbyImage`-container-element
                            // as a workaround for Safari 15.
                            imgStyle={iconStyle}
                            className='icon'
                            image={post.frontmatter.iconImage.childImageSharp.gatsbyImageData}
                        /> : null}
                        <h2 className='title'>{post.frontmatter.title}</h2>
                        <div className='subtitle'>{post.frontmatter.description}</div>
                        <hr />
                        <div className='quick-overview'>
                            {/* 1st column – Table/Labels */}
                            <div className='labels'><ul>
                                <li>Projekt:</li>
                                <li>Auftraggeber:</li>
                                <li>Zeitraum:</li>
                            </ul></div>
                            {/* 2nd column – Table/Descriptions */}
                            <div className='descriptions'><ul>
                                <li><Markdown source={post.frontmatter.project} /></li>
                                <li><Markdown source={post.frontmatter.client} /></li>
                                <li><Markdown source={post.frontmatter.duration} /></li>
                            </ul></div>
                        </div>
                        <hr />
                        <div className="chip-container">
                            {post.frontmatter.tags.map((tag, index) => (
                                <div key={index} className="chip-root">
                                    <div className="chip-left-accessory-view" />
                                    <div className="chip-label">
                                        {tag}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </header>

                {post.frontmatter.media.screenshots && post.frontmatter.media.screenshots.length ?
                    <div className='gallery'>
                        <div className='gallery--inset-spacer' aria-hidden />
                        {post.frontmatter.media.screenshots.map((screenshot, index) => (
                            <div key={index} className='gallery--image-wrapper' >
                                <img alt='Screenshot' loading='lazy' srcSet={getSrcSet(screenshot)} />
                            </div>
                        ))}
                        <div className='gallery--inset-spacer' aria-hidden />
                    </div> : null
                }

                <section className='container' dangerouslySetInnerHTML={{ __html: post.html }} />
            </article>

            {
                previous || next ?
                    <nav className='container'>
                        <ul
                            style={{
                                display: `flex`,
                                flexWrap: `wrap`,
                                justifyContent: `space-between`,
                                listStyle: `none`,
                                padding: 0,
                            }}
                        >
                            <li>
                                {previous && (
                                    <Link to={pathForPortfolioSlug(previous.fields.slug)} rel="prev">
                                        ← {previous.frontmatter.title}
                                    </Link>
                                )}
                            </li>
                            <li>
                                {next && (
                                    <Link to={pathForPortfolioSlug(next.fields.slug)} rel="next">
                                        {next.frontmatter.title} →
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </nav> : null
            }
        </Layout >
    )
}

export default PortfolioPostTemplate

export const pageQuery = graphql`
query PortfolioPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        id
        excerpt(pruneLength: 160)
        html
        frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            project
            client
            duration
            tags
            iconImage {
                publicURL
                childImageSharp {
                    gatsbyImageData(width: 100, height: 100, layout: FIXED)
                }
            }
            heroImage {
                publicURL
                childImageSharp {
                    gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
            }
            heroBackgroundPosition
            heroBackgroundColor
            media {
                screenshots {
                    publicURL
                    childImageSharp {
                        gatsbyImageData(quality: 80, height: 550, layout: FIXED)
                    }
                }
            }
        }
    }
}
`
